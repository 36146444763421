
import { API_URL } from 'constant';
import { useNetwork } from 'hooks';
import { useLocation } from 'hooks/location';
import { useCallback } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { envHost } from 'helpers/env-url';

import {
	CaptureGestureImage,
	GetIpAddress,
	locationInfoState,
	userInformationState,
} from 'states/gesture-state';
import { IsWebAuthSuccessfullState } from 'hooks/web-authn/state';
import { IsUserMatchScoreState } from 'states';

export const useGestures = () => {
	// global states
	const [ipAddress, setIpAddress] = useRecoilState(GetIpAddress);
	const [getUserImage, setUserImage] = useRecoilState(CaptureGestureImage);
	const userInformation = useRecoilValue(userInformationState);
	const IsWebAuthSuccessfull = useRecoilValue(IsWebAuthSuccessfullState);
	const userMatchScore = useRecoilValue(IsUserMatchScoreState);
	


	const locationInformation = useRecoilValue(locationInfoState);

	// hooks
	const { patch: customerPatch, post: customerPost } = useNetwork();
	const { patch: livenessPatch } = useNetwork();
	const { locationInfo } = useLocation();

	const getIpAddress = useCallback(async () => {
		// this link is use for get user ip address
		try {
			const response = await fetch(`https://api.ipify.org?format=json`);
			const data = await response.json();
			setIpAddress(data?.ip ?? '');
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn(error);
			setIpAddress('');
		}
	}, []);

	const captureImage = useCallback(() => {
		const canvas = document.getElementById(
			'output_canvas'
		) as HTMLCanvasElement;
		const video = document.getElementById('webcam') as HTMLVideoElement;

		const context = canvas?.getContext('2d');
		if (!context) {
			// eslint-disable-next-line no-console
			console.warn('Unable to get 2D context for canvas.');
			return null;
		}

		// Draw the video frame on the canvas
		context.drawImage(video, 0, 0, canvas.width, canvas.height);

		// Convert the canvas content to a data URL
		const userImage: any = canvas.toDataURL('image/jpeg');
		const baseImage = userImage.split(',')?.[1];
		if (userImage.includes('base64')) {
			setUserImage(baseImage);
			return baseImage;
		}
	}, []);

	const getOperatingSystem = (userAgent: string) => {
		if (/Android/i.test(userAgent)) {
			return 'Android';
		} else if (/Mac|iPhone|iPad|iPod/i.test(userAgent)) {
			return 'IOS';
		} else if (/Windows/i.test(userAgent)) {
			return 'Windows';
		} else {
			return 'Other';
		}
	};

	const customerPatchStatus = useCallback(
		async (
			status: string,
			customerId: string,
			userImage?: string,
			location?: any
		) => {
			const payload = {
				status,
				user_image: userImage ?? getUserImage,
				web_auth : IsWebAuthSuccessfull,
				match_score : userMatchScore,
				device_info: {
					ip: ipAddress,
					location: locationInformation.latitude
						? locationInformation
						: location ?? locationInfo,
					'user-agent': getOperatingSystem(navigator.userAgent) ?? {},
				},
			};
			// if(!IsWebAuthSuccessfull){
			// 	payload.status = livenessStatus.REJECTED
			// }
			return await customerPatch(`${API_URL.CUSTOMER}${customerId}`, payload);
		},
		[userMatchScore, IsWebAuthSuccessfull,locationInformation,ipAddress, locationInfo ,getUserImage]
	);

	const customerVerificationPatch = useCallback(
		async () => {
			const payload = {
				image: getUserImage ?? "",
				email: userInformation?.user?.email_id,
				phone: userInformation?.user?.phone_no,
				env: envHost,
				face_match: userInformation?.customer?.face_match,
			};
			return await customerPost(`${API_URL.USER_VERIFICATION}`, payload);
		},
		[ipAddress,userInformation,getUserImage,locationInfo]
	);


	const livenessPatchStatus = useCallback(
		async (
			status: string,
			livenessId: string | number,
			isUserImage?: boolean
		) => {
			const payload = {
				status,
				...(isUserImage && { user_images: getUserImage }),
			};
			return await livenessPatch(`${API_URL.LIVENESS}${livenessId}`, payload);
		},
		[getUserImage, captureImage]
	);

	return {
		customerPatchStatus,
		livenessPatchStatus,
		captureImage,
		getIpAddress,
		customerVerificationPatch,
	};
};
